import React from 'react'
import styled from 'styled-components'

import { Layout } from 'organisms'
import { Separator, Headline } from 'atoms'
import flex from 'utils/style/flex'
const S = {}

S.Wrapper = styled.section`
	${flex.col.center};
	height: 100%;
`
const NotFoundPage = () => (
	<Layout>
		<S.Wrapper>
			<Headline centered revert>
				{`Apparement il n'y a rien ici !`}
			</Headline>
			<Separator />
		</S.Wrapper>
	</Layout>
)

export default NotFoundPage
